.MissionBackground {
  background-color: #f9f9f9;
  min-height: 100vh;
  min-width: 100vh;
  @media (max-width: 820px) {
    min-width: 100%;
  }
}

.MissionTitle {
  padding-top: 100px;
  width: 100%;
  left: 0;
}

.MissionTitleText {
  margin-left: 4vh;
  font-size: 2em;
  color: #0055a6;
  font-weight: 800;
}

.MissionTitleLine {
  width: 45vh;
  height: 5px;
  background-color: #ffb335;
}

.ImageWrapper {
  width: 100%;
  margin-top: 30px;
  text-align: center;
  img {
    width: 100%;
    height: auto;
    margin: 20px 0;
  }
  h3 {
    padding-bottom: 10px;
  }
}

.MissionCardWrapper {
  background-color: rgb(0, 85, 166, 0.75);
  padding: 5vh;
  height: 100%;

  @media (max-width: 820px) {
    height: 100%;
    max-width: 100%;
  }
}

.MissionVerticalLines {
  position: absolute;
  left: 10%;
  width: 40%;
  opacity: 0.1;
  @media (max-width: 820px) {
    width: 10%;
  }
}
