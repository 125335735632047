.WorkStyleBackground {
  background-image: url("../../assets/background-workstyle.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;

  @media (max-width: 820px) {
  }
}

.WorkStyleTitle {
  padding-top: 100px;
  width: 100%;
  left: 0;
}

.WorkStyleTitleText {
  margin-left: 8vh;
  font-size: 2em;
  color: rgb(0, 85, 166);
  font-weight: 800;
}

.WorkStyleTitleLine {
  width: 45vh;
  height: 5px;
  background-color: #ffb335;
}

.WorkStyleImageWrapper {
  width: 80vh;
  height: 80vh;
  @media (max-width: 820px) {
    display: none;
  }
}

.GridWrapper {
  margin: 10vh 0vh;
  @media (max-width: 820px) {
    margin-top: 3vh;
  }
}

.GridBoxBackground {
  background-color: rgb(0, 85, 166, 0.7);
}

.CardWrapper {
  padding: 5vh;
  min-height: 190px;
  text-align: left;
}
