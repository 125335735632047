.TopNavbarBackground {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgb(0, 85, 166, 0.9);
}

.TopNavbarLogo {
  width: 150px;
  margin: 2px 0 2px 20vh;
  @media (max-width: 820px) {
    margin: 0;
  }
}

// .NavInner {
//   position: relative;
//   height: 100%;
// }

// .BurderWrapper {
//   outline: none;
//   border: 0px;
//   background-color: transparent;
//   height: 100%;
//   padding: 0 15px;
//   display: none;
//   @media (max-width: 760px) {
//     display: block;
//   }
// }

// .UlWrapper {
//   display: flex;
//   @media (max-width: 760px) {
//     display: none;
//   }
// }

// .UlWrapperRight {
//   @media (max-width: 760px) {
//     display: none;
//   }
// }
