.SideBarWrapper {
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: -500px;

  z-index: 9999;
  @media (max-width: 400px) {
    width: 100%;
  }
}

.SidebarHeader {
  padding: 20px 0;
}

.SideBarCloseBtn {
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
}

.SideBarUlStyle {
  padding: 40px;
  li {
    margin: 20px 0;
  }
}