.WhoWeAreBackground {
  background: linear-gradient(90deg, #bfdbef 30%, #f9f9f9 0%);
  min-height: 100vh;
  min-width: 100vh;
  @media (max-width: 820px) {
    background: none;
    min-width: 100%;
    background-color: #f9f9f9;
  }
}

.WhoWeAreTitle {
  padding-top: 100px;
  width: 100%;
  left: 0;
}

.WhoWeAreTitleText {
  margin-left: 15vh;
  font-size: 2em;
  color: #0055a6;
  font-weight: 800;
}

.WhoWeAreTitleLine {
  width: 45vh;
  height: 5px;
  background-color: #ffb335;
}
