.HomeWrapper {
  background-image: url("../../assets/background-image.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  min-width: 100%;
  @media (max-width: 820px) {
    max-width: 100%;
  }
}

.HomeLeftSide {
  width: 50%;
  height: 100%;
  max-width: 500px;
  text-align: center;
  padding-top: 15vh;
  margin-left: 25vh;

  @media (max-width: 820px) {
    display: block;
    width: 50%;
    margin-left: 5vh;
  }
}

.HomeTitle {
  font-size: 112px;
}

.HomeVerticalLines {
  position: absolute;
  left: -8%;
  opacity: 0.1;
  z-index: 0;
  @media (max-width: 820px) {
    height: 100vh;
    max-width: 100%;
  }
}
