.ArticlesBackground {
  background-image: url("../../assets/background-articles.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 85vh;

  @media (max-width: 820px) {
    min-width: 10px;
  }
}

.ArticlesTitle {
  padding-top: 100px;
  width: 100%;
  left: 0;
}

.ArticlesTitleText {
  margin-left: 20vh;
  font-size: 2em;
  color: #0055a6;
  font-weight: 800;
}

.ArticlesTitleLine {
  width: 45vh;
  height: 5px;
  background-color: #ffb335;
}

.DotActive {
  color: #0055a6;
}

.DotInactive {
  color: gray;
}
