.FooterBackground {
  background-color: #0055a6;
  padding: 0px 50px 50px 50px;
}

.InnerWrapper {
  @media (max-width: 800px) {
    flex-direction: column;
  }
}

.FooterBorder {
  width: 100%;
  height: 5px;
  background-color: #ffb335;
}

.logos-rodape {
  padding: 30px 0;
  position: relative;
  z-index: 10;

  .container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    img {
      width: 150px;
    }
  }
}
