.FaqBackground {
  background-color: #0055a6;
}

.FaqTitle {
  padding-top: 100px;
  width: 100%;
  left: 0;
}

.FaqTitleText {
  margin-left: 5vh;
  font-size: 2em;
  color: white;
  font-weight: 800;
}

.FaqTitleLine {
  width: 45vh;
  height: 5px;
  background-color: #ffb335;
}

.FaqVerticalLines {
  position: absolute;
  left: 20%;
  width: 75vh;
  opacity: 0.2;
  @media (max-width: 820px) {
    height: 100vh;
    max-width: 50%;
  }
}
