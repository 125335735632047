.ProductsBackground {
  background: linear-gradient(90deg, #bfdbef 30%, #f9f9f9 0%);
  min-width: 100vh;
  min-height: 100vh;

  @media (max-width: 820px) {
    min-width: 100%;
    background: none;
    background-color: #bfdbef;
  }
}

.ProductsTitle {
  padding-top: 100px;
  width: 100%;
  left: 0;
}

.ProductsTitleText {
  margin-left: 4vh;
  font-size: 2em;
  color: #0055a6;
  font-weight: 800;
}

.ProductsTitleLine {
  width: 45vh;
  height: 5px;
  background-color: #ffb335;
}

.Stocks {
  width: 40vh;
  height: 70vh;
  margin-top: 5vh;
  margin-left: 25vh;
  @media (max-width: 820px) {
    display: none;
  }
}

.MoneyBag {
  width: 80px;
}

.BodyBackground {
  @media (max-width: 820px) {
    min-width: 50%;
    padding-left: 1vh;
  }
}

.BodyTitle {
  width: 100%;
}

.ProductsVerticalLines {
  position: absolute;
  left: 10%;
  width: 87%;
  opacity: 0.1;
  @media (max-width: 820px) {
    height: 100vh;
    max-width: 100%;
  }
}
